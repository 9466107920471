<template>
  <div>
    <v-simple-table fixed-header class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">{{ $t('labels.classify') }}</th>
            <th role="columnheader" class="text-center" style="width: 25%">{{ $t('labels.pos') }}</th>
            <th role="columnheader" class="text-center" style="width: 25%">{{ $t('labels.object') }}</th>
            <th role="columnheader" class="text-center" style="width: 25%">{{ $t('labels.from_to') }}</th>
            <th role="columnheader" class="text-center"></th>
            <th role="columnheader" class="text-center"></th>
          </tr>
        </thead>
        <Timekeeping v-if="checkPermission(['pos_admin_timekeeping'])" @exportExcel="exportExcel" />
        <Payroll v-if="checkPermission(['pos_admin_payroll'])" @exportExcel="exportExcel"/>
        <!-- <Bill v-if="checkPermission(['pos_admin_bill'])" @exportExcel="exportExcel"/> -->
        <!-- <Package v-if="checkPermission(['pos_admin_package'])" @exportExcel="exportExcel"/> -->
      </template>
    </v-simple-table>

    <v-simple-table fixed-header class="table-padding-2 mt-3">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">{{ $t('labels.classify') }}</th>
            <th role="columnheader" class="text-center">{{ $t('labels.name') }}</th>
            <th role="columnheader" class="text-center">{{ $t('labels.status') }}</th>
            <th role="columnheader" class="text-center">{{ $t('labels.time') }}</th>
            <th role="columnheader" class="text-center">{{ $t('labels.user_export') }}</th>
            <th role="columnheader" class="text-center">{{ $t('labels.file') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-for="item in items" :key="`er_${item.id}`">
            <td>{{ typeTxt[item.type] }}</td>
            <td>{{ item.name }}</td>
            <td>{{ statusTxt[item.status] }}</td>
            <td>{{ item.created_at ? formatDateTime(item.created_at) : '' }}</td>
            <td>{{ item.display_name }}</td>
            <td><v-btn v-if="canDownload(item)" small color="success"
                @click="downloadFile(item.output)"><v-icon>mdi-download</v-icon></v-btn></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="d-flex pt-3">
      <v-col cols="11">
        <v-pagination v-model="page" :length="totalPage" :total-visible="5"></v-pagination>
      </v-col>
      <v-col cols="1">
        <v-text-field dense outlined hide-details v-model.number="page" :label="$t('labels.page')"
          :placeholder="$t('labels.page')"></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
export default {
  name: "Index",
  components: {
    Timekeeping: () => import('@/components/administration/Timekeeping'),
    Payroll: () => import('@/components/administration/Payroll'),
    // Bill: () => import('@/components/administration/Bill'),
    // Package: () => import('@/components/administration/Package'),
    // Order: () => import('@/components/administration/Order'),
    // EmployeePerformance: () => import('@/components/administration/EmployeePerformance'),
    // IncompleteOutbound: () => import('@/components/administration/IncompleteOutbound'),
    // CustomerMonitor: () => import('@/components/administration/CustomerMonitor'),
    //Control: () => import('@/components/administration/Control'),
    //Payment: () => import('@/components/administration/Payment'),
  },
  data: () => ({
    isWaitingDownload: true,
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    typeTxt: {},
    statusTxt: {}
  }),
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  created() {
    const typeTxt = {
      pos_bill: this.$t('labels.bill'),
      pos_control: '',
      pos_order: this.$t('labels.admin_order_report'),
      pos_package: this.$t('labels.packaging'),
      pos_payment: '',
      pos_payroll: this.$t('labels.cal_payroll'),
      pos_timekeeping: this.$t('labels.timekeeping'),
      pos_employee_performances: this.$t('labels.employee_performance'),
      pos_incomplete_outbound: this.$t('labels.incomplete_outbound'),
      pos_customer_monitor: this.$t('labels.customer_monitor'),
    }
    const statusTxt = {
      pending: this.$t('labels.pending'),
      processing: this.$t('labels.processing'),
      done: this.$t('labels.done'),
      error: this.$t('labels.error'),
    }
    this.typeTxt = { ...typeTxt }
    this.statusTxt = { ...statusTxt }
  },
  mounted() {
    this.getList()
    this.listenSocket()
  },
  methods: {
    getList: debounce(function () {
      httpClient.post('/export-request-list', {...this.filters, page: this.page}).then(({data}) => {
        this.totalPage = data.totalPage
        this.items = [...data.rows]
      })
    }, 1000),
    canDownload(item) {
      return item.status === 'done' && window.me && window.me.identity_id === item.id_identity
    },
    downloadFile(file_path) {
      const split_file_path = file_path.split('/')
      let filename = split_file_path[split_file_path.length - 1]
      if (filename.includes('employee_performances')) {
        filename = 'employee_performances'
      } else if (filename.includes('incomplete_outbound')) {
        filename = 'incomplete_outbound_monitor'
      } else if (filename.includes('customer_monitor')) {
        filename = 'customer_monitor'
      }
      this.downloadExcelFile('/download-file-export-request', { file_path }, filename)
    },
    async exportExcel({ params, type }) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }

      this.isLoading = true
      try {
        await httpClient.post('/export-request-create', { params, type })
        this.isLoading = false
        this.$vToastify.warning(this.$t('messages.waiting_file_download'))
      } catch (e) {
        this.isLoading = false
      }
    },
    listenSocket() {
      window.socket.on('export_request_file_path', data => {
        if (this.isWaitingDownload) {
          const { file_path, msg } = data
          if (msg) {
            this.$vToastify.error(msg)
          } else {
            this.downloadFile(file_path)
            /*const split_file_path = file_path.split('/')
            let filename = split_file_path[split_file_path.length - 1]
            if (filename.includes('employee_performances')) {
              filename = 'employee_performances'
            }
            this.downloadExcelFile('/download/v1/file-export-request', data, filename)*/
            // const downloadPath = `${process.env.VUE_APP_FILE_CDN_URL}/${file_path}`
            // window.open(downloadPath)
          }
        }
      })
    },
  }
}
</script>

<style scoped></style>
